import { Alert, Card, Row, Col, Space, Typography } from 'antd';
import { useState, useRef, useEffect, useMemo } from 'react';
import { ProjectSoftwareAppsList } from './ProjectSoftwareAppsList';
import { AddProjectSoftwareApp } from './AddProjectSoftwareApp';
import { SoftwareAppSelection } from '../../../domain';
import { useBundleConfigurationVersion } from '../../bundles/hooks/useBundleConfigurationVersion';
import { useBundles } from '../../bundles/hooks/useBundles';
import { useDeleteBundleConfiguration } from '../../bundles/hooks/useDeleteBundleConfiguration';
import { useDeleteBundleConfigurationVersion } from '../../bundles/hooks/useDeleteBundleConfigurationVersion';
import { CreateDeploymentPlan } from '../../deployments/components/CreateDeploymentPlan';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ProjectSoftwareAppsCompareList } from './ProjectSoftwareAppsCompareList';
import { AddBundleConfiguration } from '../../bundles/components/AddBundleConfiguration';
import { AddBundleConfigurationVersion } from '../../bundles/components/AddBundleConfigurationVersion';
import { BundleConfigurationVersionDropdown } from '../../bundles/components/BundleConfigVersionDropdown';
import { ReportDownloadButton } from '../../bundles/components/BundleReportDownloadButton';
import { ComparisonViewSwitch } from '../../bundles/components/ComparisonViewSwitch';
import { RevertButton } from '../../bundles/components/RevertButton';
import { ShowBundleItemsOnlySwitch } from '../../bundles/components/ShowBundleItemsOnlySwitch';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { scopeToCommon, scopeToProject } from '../hooks/scopedSoftwareApp';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { BundleRelease } from '../../../api/engineering/domain/types';
import { UpdateBundleConfiguration } from '../../bundles/components/EditBundleConfiguration';

const { Text } = Typography;

type SoftwareAppsBundleViewProps = {
  projectId: string;
};

const initialBundleSelection: BundleConfigurationSelection = {
  bundleId: '',
  projectId: '',
  bundleConfigurationId: '',
  bundleConfigurationVersionId: '',
  bundleConfigurationName: '',
  bundleConfigurationVersionName: ''
};

export const ProjectSoftwareAppsBundleView = (props: SoftwareAppsBundleViewProps) => {
  const [selectedBundleConfigPrimary, setSelectedBundleConfigPrimary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const [selectedBundleConfigSecondary, setSelectedBundleConfigSecondary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const projectId = useMemo(() => {
    setSelectedBundleConfigPrimary(initialBundleSelection);
    setSelectedBundleConfigSecondary(initialBundleSelection);
    return props.projectId;
  }, [props.projectId, setSelectedBundleConfigPrimary, setSelectedBundleConfigSecondary]);
  const permissions = usePermissions({ projectId: projectId.toString() });
  const emptyArray = useRef([]);
  const [selectedApps, setSelectedApps] = useState<SoftwareAppSelection[] | undefined>();
  const [initialSelection, setInitialSelection] = useState([] as SoftwareAppSelection[]);
  const [comparisonViewSearchParam, setComparisonViewSearchParam] = useSearchParameter('mode');
  const [isDirty, setIsDirty] = useState(false);
  const [showSearchParameter] = useSearchParameter('show');

  const comparisonView = comparisonViewSearchParam === 'compare';
  const setComparisonView = (enabled: boolean) => setComparisonViewSearchParam(enabled ? 'compare' : 'control');

  const project = useProject(projectId);
  const bundles = useBundles(projectId);

  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes('Software Applications'));
  const bundleId = usedBundle?.idBundle?.toString() || '';

  const deleteBundleConfigVersion = useDeleteBundleConfigurationVersion();
  const deleteBundleConfig = useDeleteBundleConfiguration();

  const showBundleItemsOnly = !showSearchParameter || showSearchParameter === 'bundle';

  // if a project is an rpl and has a bundle config, no further can be added
  const isRpl = !!project.data?.projectType.isReferenceProject;
  const addBundleConfigDisabledDueToRpl = isRpl && !!selectedBundleConfigPrimary.bundleConfigurationId;

  const bundleReleaseA = useBundleConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigPrimary.bundleConfigurationId,
    selectedBundleConfigPrimary.bundleConfigurationVersionId
  );

  const bundleReleaseB = useBundleConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigSecondary.bundleConfigurationId,
    selectedBundleConfigSecondary.bundleConfigurationVersionId
  );

  const extractSelectionFromBundleRelease = useMemo(
    () => (data: BundleRelease) => {
      if (!data) {
        return emptyArray.current;
      }
      const matchedProjectApps = (data.projectSoftwareApps || []).map(scopeToProject).map((et) => {
        return { app: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      const matchedCommonApps = (data.softwareApps || []).map(scopeToCommon).map((et) => {
        return { app: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      return [...matchedProjectApps, ...matchedCommonApps];
    },
    []
  );

  const extractedAppsLeft = useMemo(() => {
    if (!bundleReleaseB.isSuccess) {
      return undefined;
    }
    return extractSelectionFromBundleRelease(bundleReleaseB.data);
  }, [bundleReleaseB.isSuccess, bundleReleaseB.data, extractSelectionFromBundleRelease]);

  const handleBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    setSelectedBundleConfigPrimary(val);
  };

  const handleSecondaryBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    setSelectedBundleConfigSecondary(val);
  };

  useEffect(() => {
    if (bundleReleaseA.data) {
      setInitialSelection(extractSelectionFromBundleRelease(bundleReleaseA.data));
      setSelectedApps(extractSelectionFromBundleRelease(bundleReleaseA.data));
    } else {
      setInitialSelection([]);
      setSelectedApps(undefined);
    }
  }, [bundleReleaseA.data, setInitialSelection, setSelectedApps, extractSelectionFromBundleRelease]);

  const configDropdownExtensions =
    (!comparisonView && [
      <ExpandableMenu id={`bundle-config-actions-${props.projectId}-${bundleId}`}>
        {permissions.engineeringSvc$addProjectBundleConfiguration && (
          <AddBundleConfiguration
            disabled={addBundleConfigDisabledDueToRpl}
            projectId={projectId.toString()}
            bundleId={bundleId}
            onAddBundleConfiguration={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$updateProjectBundleConfiguration && (
          <UpdateBundleConfiguration
            projectId={projectId.toString()}
            bundleId={bundleId}
            configId={selectedBundleConfigPrimary.bundleConfigurationId}
            onEditBundleConfigurationName={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: bc.latestBundleReleaseId?.toString() || '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfiguration && (
          <ConfirmationButton
            caption="Delete Bundle Configuration"
            danger
            description="All deployment plans associated with this configuration will also be deleted."
            onOk={() => {
              deleteBundleConfig.mutate([projectId.toString(), bundleId, selectedBundleConfigPrimary.bundleConfigurationId]);
            }}
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const configVersionDropdownExtensions =
    (!comparisonView && [
      <RevertButton disabled={!isDirty} onClick={() => setSelectedApps(initialSelection)} />,
      <ExpandableMenu id={`bundle-config-version-actions-${props.projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
          <AddBundleConfigurationVersion
            projectId={projectId.toString()}
            bundleId={bundleId}
            bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
            selectedEngineeringToolVersionIds={emptyArray.current}
            selectedSoftwareAppVersionIds={(selectedApps ?? [])
              .filter((sa) => sa.app.scope === 'common')
              .map((sa) => sa.version.idSoftwareAppVersion || -1)
              .filter((sai) => sai > -1)}
            selectedProjectSoftwareAppVersionIds={(selectedApps ?? [])
              .filter((sa) => sa.app.scope === 'project')
              .map((sa) => sa.version.idSoftwareAppVersion || -1)
              .filter((sai) => sai > -1)}
            onAddBundleConfigurationVersion={(bcv) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfigurationRelease && (
          <ConfirmationButton
            caption="Delete Version"
            danger
            onOk={() =>
              deleteBundleConfigVersion.mutate([
                projectId.toString(),
                bundleId,
                selectedBundleConfigPrimary.bundleConfigurationId,
                selectedBundleConfigPrimary.bundleConfigurationVersionId
              ])
            }
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const bundleActions = (
    <>
      <ExpandableMenu id={`bundle-config-version-extra-actions-${props.projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        <ComparisonViewSwitch state={comparisonView} onChange={() => setComparisonView(!comparisonView)} />
        {!comparisonView && permissions.engineeringSvc$addProjectSoftwareApp && <AddProjectSoftwareApp projectId={projectId.toString()} />}
        {!comparisonView &&
          permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport &&
          project.data &&
          usedBundle &&
          selectedBundleConfigPrimary && (
            <ReportDownloadButton project={project.data} bundle={usedBundle} bundleConfig={selectedBundleConfigPrimary} isDirty={isDirty} />
          )}
        {!comparisonView && permissions.deploymentSvc$addDeploymentPlan && (
          <CreateDeploymentPlan
            projectId={parseInt(projectId)}
            bundleId={parseInt(bundleId)}
            disabled={isDirty || !selectedApps}
            bundleConfigId={parseInt(selectedBundleConfigPrimary.bundleConfigurationId)}
            bundleConfigVersionId={parseInt(selectedBundleConfigPrimary.bundleConfigurationVersionId)}
            selection={selectedApps ?? []}
          />
        )}
      </ExpandableMenu>
    </>
  );

  const aIsEmpty = !selectedBundleConfigPrimary.bundleConfigurationVersionId && selectedBundleConfigPrimary.bundleId;
  const bIsEmpty = !selectedBundleConfigSecondary.bundleConfigurationVersionId && selectedBundleConfigSecondary.bundleId;
  const aLoading = (bundleReleaseA.isLoading || bundleReleaseA.data === undefined) && !aIsEmpty;
  const bLoading = comparisonView && (bundleReleaseB.isLoading || bundleReleaseB.data === undefined) && !bIsEmpty;
  const loading = aLoading || bLoading;

  useEffect(() => {
    if (selectedApps === undefined && aIsEmpty) {
      setSelectedApps([]);
    }
  }, [aIsEmpty, selectedApps, setSelectedApps]);

  // TODO: extract validation for ids and use in hooks as well
  if (parseInt(projectId, 10) < 0) {
    return <Alert message="Invalid Project Id" type="error" />;
  }

  return (
    <Card loading={project.isLoading}>
      <Row justify="start">
        <Col flex="50%">
          {!comparisonView && bundleReleaseA.data && (
            <>
              <Text type="secondary">Release Notes:</Text>
              <MarkdownPreview content={bundleReleaseA.data.releaseNotes || ''} title="Release note" />
              <br />
              <Text type="secondary">
                {bundleReleaseA.data.createdAt ? formatDateTime(new Date(bundleReleaseA.data.createdAt)) : ''}
                {bundleReleaseA.data.createdBy ? ` by ${bundleReleaseA.data.createdBy}` : ''}
              </Text>
            </>
          )}
        </Col>
        <Col flex="auto">
          <Space direction="vertical" style={{ float: 'right' }} size={0}>
            <Space style={{ float: 'right' }}>
              {comparisonView && (
                <BundleConfigurationVersionDropdown
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigSecondary}
                  onSelect={handleSecondaryBundleConfigVersionChange}
                  id="l"
                />
              )}
              <BundleConfigurationVersionDropdown
                projectId={projectId.toString()}
                bundleId={bundleId}
                selected={selectedBundleConfigPrimary}
                onSelect={handleBundleConfigVersionChange}
                disableLabel={comparisonView}
                isDirty={isDirty}
                configurationDropdownExtensions={configDropdownExtensions}
                configurationVersionDropdownExtensions={configVersionDropdownExtensions}
                id="r"
              />
            </Space>
            <Space style={{ float: 'right' }}>
              {!comparisonView && <ShowBundleItemsOnlySwitch itemName="Apps" extension={[bundleActions]} />}
              {comparisonView && bundleActions}
            </Space>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          {!comparisonView && project.data && (
            <ProjectSoftwareAppsList
              onDirty={setIsDirty}
              project={project.data}
              selected={selectedApps}
              initiallySelected={initialSelection}
              onSelect={(selected) => setSelectedApps([...selected])}
              showBundleItemsOnly={showBundleItemsOnly}
              loading={loading}
            />
          )}
          {comparisonView && (
            <ProjectSoftwareAppsCompareList
              project={project.data}
              selectedTitleLeft={`${selectedBundleConfigSecondary.bundleConfigurationName}: ${selectedBundleConfigSecondary.bundleConfigurationVersionName}`}
              selectedTitleRight={`${selectedBundleConfigPrimary.bundleConfigurationName}: ${selectedBundleConfigPrimary.bundleConfigurationVersionName}`}
              projectId={projectId.toString()}
              selectedAppsRight={selectedApps}
              selectedAppsLeft={extractedAppsLeft}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
};
