import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { Project } from '../../../api/engineering/domain/types';
import { EngineeringToolSelection } from '../../../domain/engineeringToolsSelection';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../domain/toolmanager/toolmanagerArgs';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { PacTSIcon } from '../../shared/components/icons/PacTSIcon';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

type EngineeringToolsOpenInToolmanagerButtonProps = {
  selection: EngineeringToolSelection;
  project?: Project;
};

export const EngineeringToolsOpenInToolManagerButton = (props: EngineeringToolsOpenInToolmanagerButtonProps) => {
  const disabled = props.selection.version.state !== 'consistent';
  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    toolId: props.selection.engineeringTool.id.toString(),
    idToolVersion: props.selection.version.idToolVersion.toString()
  });

  if (!permissions.impacts$use) return null;

  const onClick = () => {
    const component: OpenComponentArgs = {
      component: {
        category: props.selection.engineeringTool.category?.map((c) => c.name!).join(', ') || '',
        id: props.selection.engineeringTool.id!.toString(),
        kind: props.selection.engineeringTool.packageKind!,
        name: props.selection.engineeringTool.name,
        scope: 'common',
        targets: [
          {
            targetId: -1,
            downloadLink: props.selection.version.downloadLink,
            targetName: 'unknown',
            sha256: props.selection.version.sha256
          }
        ],
        type: 'tool',
        version: props.selection.version.version,
        versionId: props.selection.version.idToolVersion!.toString(),
        context: props.project ? { projectId: props.project.idProject!.toString(), projectName: props.project.name } : undefined
      }
    };
    const args: ToolManagerArgs = {
      action: 'openComponent',
      payload: component
    };

    metrics
      .submitClick({
        operationId: MetricClickIds.openInImpacts
      })
      .withToolVersionContext(props.selection.engineeringTool, props.selection.version);

    ToolManagerLink.open(args);
  };

  return (
    <>
      <ExpandableMenuItem disabled={disabled} icon={<PacTSIcon />} onClick={onClick}>
        Open in ImPacTS
      </ExpandableMenuItem>
    </>
  );
};
