import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import { usePermissions } from '../../session/hooks/usePermissions';
import { SoftwareComponentOpenCommonAppInToolmanager } from './SoftwareComponentOpenCommonAppInToolmanager';
import { SoftwareComponentOpenProjectAppInToolmanager } from './SoftwareComponentOpenProjectAppInToolmanager';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { Project, SoftwareComponent } from '../../../api';
import { PacTSIcon } from '../../shared/components/icons/PacTSIcon';

export const SoftwareComponentOpenInToolmanagerGateway = (props: { project: Project; component: SoftwareComponent }) => {
  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    toolId: props.component.id.toString(),
    softwareAppId: props.component.id.toString(),
    idToolVersion: props.component.versionId.toString(),
    idSoftwareAppVersion: props.component.versionId.toString()
  });
  const metrics = useSubmitMetric();

  const [download, setDownload] = useState<any>(undefined);

  if(!permissions.impacts$use) return null;
  
  const isProjectScope = props.component.scope === 'project' 
  const isCommonScope = props.component.scope === 'common'

  if (!isProjectScope && !isCommonScope) return null;

  const onClick = () => {
    if (isProjectScope) {
      metrics.submitClick({ operationId: MetricClickIds.openInImpacts }).withSoftwareComponent(props.component, props.project);

      setDownload(
        <SoftwareComponentOpenProjectAppInToolmanager
          projectId={props.project.idProject.toString()}
          appId={props.component.id.toString()}
          versionId={props.component.versionId.toString()}
          onLoad={() => setDownload(undefined)}
        />
      );
    } else if (isCommonScope) {
      metrics.submitClick({ operationId: MetricClickIds.openInImpacts }).withSoftwareComponent(props.component);

      setDownload(
        <SoftwareComponentOpenCommonAppInToolmanager
          projectId={props.project.idProject.toString()}
          appId={props.component.id.toString()}
          versionId={props.component.versionId.toString()}
          onLoad={() => setDownload(undefined)}
        />
      );
    }
  };

  return (
    <>
      <div style={{ visibility: 'collapse' }}>{download}</div>

      <Tooltip title="Open in ImPacTS">
        <Button icon={<PacTSIcon />} type="text" loading={download} onClick={() => onClick()} />
      </Tooltip>
    </>
  );
};
