import { VersionedGenericSoftwareComponent } from './softwareComponent';

export type OpenComponentArgs = {
  component: VersionedGenericSoftwareComponent;
};

export type LoginArgs = {
  code: string;
};

export type ToolManagerArgs = {
  action: 'openComponent' | 'login';
  payload: VersionedGenericSoftwareComponent | LoginArgs | any;
};

export abstract class ToolManagerLink {
  public static open(args: ToolManagerArgs) {
    const base64 = btoa(JSON.stringify(args));
    window.open(`/impacts?link=${base64}`);
  }
}
